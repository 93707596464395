import { logger } from '@springtree/eva-sdk-core-logger';
import { createServiceDefinition, Core, IEvaServiceDefinition } from '@springtree/eva-services-core';
import { EvaService, IEvaServiceCallOptions } from './service';

/**
 * The data object representing a bootstrapped endpoint
 * Can be used to serialise/de-serialise to a Storage provider
 *
 * @export
 * @interface IEvaEndpointSerializedData
 */
export interface IEvaEndpointSerializedData {
  endPointUri: string;
  configuration: EVA.Core.GetApplicationConfigurationResponse|undefined;
  bootstrappedAt: number;
}

/**
 * Provides bootstrap logic for an EVA endpoint.
 * We need to bootstrap to get access to applications and configuration
 * This provides us with details such as the default token, base urls, etc.
 * We can bootstrap both cloud and on-premise EVA endpoints the same way
 *
 * @export
 * @class EvaEndpoint
 */
export class EvaEndpoint {

  /**
   * The endpoint URL for the EVA backend
   *
   * @type {string}
   */
  public readonly endPointUri: string;

  /**
   * The default configuration for the EVA endpoint
   *
   * @private
   * @type {EVA.Core.GetApplicationConfigurationResponse}
   */
  private configuration: EVA.Core.GetApplicationConfigurationResponse|undefined;

  /**
   * Unix timestamp in milliseconds when the bootstrap was done
   *
   * @private
   * @type {number}
   */
  public bootstrappedAt: number = 0;

  /**
   * Getter for boolean state indicating the endpoint has been bootstrapped
   *
   * @readonly
   * @type {boolean}
   */
  public get hasBootstrapped(): boolean {
    return !!this.configuration;
  }

  /**
   * Creates an instance of EvaEndpoint
   * Can either be a simple endpoint or serialised data
   *
   * @param {string} endPointUri The uri for the EVA endpoint to bootstrap
   */
  constructor(
    endPointUriOrSerialisedData: string|IEvaEndpointSerializedData,
  ) {
    if (typeof endPointUriOrSerialisedData === 'string') {
      this.endPointUri = endPointUriOrSerialisedData;
    } else {
      const data: IEvaEndpointSerializedData = endPointUriOrSerialisedData;
      this.endPointUri = data.endPointUri;
      this.configuration = data.configuration;
      this.bootstrappedAt = data.bootstrappedAt;
    }
  }

  /**
   * Create a data object representation of the EVA Endpoint instance
   *
   * @returns {IEvaEndpointSerializedData}
   */
  public serialise(): IEvaEndpointSerializedData {
    const data: IEvaEndpointSerializedData = {
      endPointUri: this.endPointUri,
      configuration: this.configuration,
      bootstrappedAt: this.bootstrappedAt,
    };

    return data;
  }

  /**
   * Perform the bootstrap calls and setup current application
   */
  public async bootstrap(params?: { timeout?: number }) {
    logger.debug(`[EVA:ENDPOINT] Bootstrapping ${this.endPointUri}...`)();

    // We will disable the EVA service interceptors to not hit on premise or
    // other behaviour changing logic. This logic should not work before the
    // bootstrap anyway
    //
    // Fetch application configuration
    //
    const getApplicationConfiguration = new EvaService<Core.GetApplicationConfiguration>(
      createServiceDefinition(Core.GetApplicationConfiguration),
      this,
      { disableInterceptors: true, disableEndpointBootstrapCheck: true },
    );

    const applicationConfiguration = await getApplicationConfiguration.call({
      timeout: params?.timeout,
    });
    this.configuration = applicationConfiguration.response;

    this.bootstrappedAt = +(new Date());
    logger.debug(`[EVA:ENDPOINT] Completed bootstrap for ${this.endPointUri}`)();
  }

  /**
   * Returns the current application configuration
   * Will not be populated until bootstrap is called
   *
   * @returns {(EVA.Core.GetApplicationConfigurationResponse|undefined)}
   */
  public getApplicationConfiguration(): EVA.Core.GetApplicationConfigurationResponse|undefined {
    // Return a copy so callers can't modify internal data
    //
    return this.configuration ? JSON.parse(JSON.stringify(this.configuration)) : undefined;
  }

  /**
   * Prepare to call the EVA backend with an instance of the EvaService class
   *
   * @template T
   * @param {new () => T} serviceDefinition
   * @returns EvaService<T>
   */
  public prepareService<T extends IEvaServiceDefinition>(serviceDefinition: new () => T): EvaService<T> {
    return new EvaService<T>(
      createServiceDefinition(serviceDefinition),
      this,
    );
  }

  /**
   * Calls an EVA service on the endpoint
   *
   * @template T
   * @param {new () => T} serviceDefinition
   * @param {T['request']} [payload]
   * @param {IEvaServiceCallOptions} [options]
   * @returns {Promise<T['response']>}
   */
  public async callService<T extends IEvaServiceDefinition>(
    serviceDefinition: new () => T,
    payload?: T['request'],
    options?: IEvaServiceCallOptions,
  ): Promise<T['response']> {
    // Build the EVA Service instance using the typed prepare method
    //
    const service = this.prepareService(serviceDefinition);

    // Set the request payload
    //
    service.data.request = payload;

    // Call using provided options
    //
    const result = await service.call(options);
    return result.response as T['response'];
  }
}
